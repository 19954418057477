const additionalFeeFormOptions = {
  mamikos_commission_type: [
    'basic_commission',
    'full_to_owner',
    'manually_adjusted',
  ],
  fee_type: ['fixed', 'optional'],
  fee_appearance_option: [
    'name_price_visible',
    'name_price_invisible',
    'name_visible',
  ],
  is_fee_included_in_listing: [true, false],
};

const additionalFeeFormatter = {
  payment_type: {
    fixed: 'Tetap',
    once: 'Satu Kali',
  },
  time_unit: {
    daily: 'Harian',
    weekly: 'Mingguan',
    monthly: 'Bulanan',
  },
  fee_unit: {
    kg: 'Kilogram (kg)',
    item: 'Item/pcs',
    person: 'Per orang',
  },
  tenant_can_choose: {
    true: 'Ya',
    false: 'Tidak',
  },
  mamikos_commission_type: {
    basic_commission: 'Sesuai Basic Commission',
    full_to_owner: 'Full ke Pemilik',
    manually_adjusted: 'Masukkan Manual',
  },
  fee_type: {
    fixed: 'Wajib',
    optional: 'Opsional',
  },
  fee_appearance_option: {
    name_price_visible: 'Nama biaya dan harga ditampilkan',
    name_price_invisible: 'Nama biaya dan harga tidak ditampilkan',
    name_visible: 'Hanya nama biaya yang ditampilkan',
  },
  is_fee_included_in_listing: {
    true: 'Ya',
    false: 'Tidak',
  },
};

const additionalFeeLabelFormatter = {
  payment_type: {
    fixed: 'blue',
    once: 'yellow',
  },
  is_chooseable_by_tenant: {
    true: 'green',
    false: 'yellow',
  },
};

export {
  additionalFeeFormOptions,
  additionalFeeFormatter,
  additionalFeeLabelFormatter,
};
