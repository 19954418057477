<template>
  <bg-modal
    :title="`${isEdit ? 'Ubah' : 'Tambah'} Biaya Tambahan`"
    :value="isOpen"
    desktop-size="lg"
    class="additional-fee-modal"
    data-testid="additional-fee-modal"
    @close="closeModal()"
  >
    <bg-grid class="bg-u-mt-lg">
      <bg-grid-item :col="6">
        <bg-text size="body-1">Nama Biaya</bg-text>
        <bg-text
          v-if="isEdit"
          size="body-2"
          class="bg-u-mt-xxs"
          data-testid="edit-modal-fee-name"
        >
          {{ editData.name }}
        </bg-text>
        <bg-select
          v-else
          v-model="form.additional_fee_rule_id"
          :options="additionalFeeOptionsFormatted"
          options-value-key="id"
          options-label-key="name"
          placeholder="Pilih nama biaya"
          searchable
          search-placeholder="Cari"
          class="bg-u-mt-xs"
          data-testid="search-additional-fee-name"
        />
      </bg-grid-item>

      <bg-grid-item v-if="isEditListingLevel" :col="6">
        <bg-text size="body-1">Listing</bg-text>
        <bg-text
          v-if="isEdit"
          size="body-2"
          class="bg-u-mt-xxs"
          data-testid="edit-modal-listing-name"
        >
          {{ editData.selectedRoom.name }}
        </bg-text>
      </bg-grid-item>
    </bg-grid>

    <bg-grid v-if="!isEdit">
      <bg-grid-item :col="6">
        <div class="flex justify-space-between">
          <bg-text size="body-1">Terapkan ke semua listing</bg-text>
          <bg-switch v-model="form.isToAllListing" />
        </div>
      </bg-grid-item>
    </bg-grid>

    <bg-grid v-show="!form.isToAllListing">
      <bg-grid-item :col="6">
        <bg-field label="Pilih Listing">
          <search-checkbox
            name="selected-listing"
            placeholder="Pilih listing yang akan diterapkan"
            :list="listingOptionsFormatted"
            :checked="form.room_ids"
            :max-checked="0"
            disable-search
            disable-clear
            selected-items-to-show="label"
            @emit-checked-array="form.room_ids = $event"
          />
        </bg-field>
      </bg-grid-item>
    </bg-grid>

    <template v-if="form.additional_fee_rule_id">
      <div
        v-if="isLoadingCheckAdditionalFee"
        class="additional-fee-modal__information-wrapper"
      >
        <bg-grid>
          <bg-grid-item :col="6" v-for="index in 4" :key="index">
            <bg-skeleton width="50%" height="24px" />
            <bg-skeleton width="100%" height="48px" />
          </bg-grid-item>
        </bg-grid>
      </div>
      <div v-else class="additional-fee-modal__information-wrapper">
        <bg-grid>
          <bg-grid-item :col="6">
            <bg-text size="title-5">Tipe Pembayaran Biaya</bg-text>
            <bg-label-rainbow
              :color="formLabelFormatter.payment_type[selectedFee.payment_type]"
              class="bg-u-mt-xs"
              data-testid="payment-type-label"
            >
              {{ formOptionsFormatter.payment_type[selectedFee.payment_type] }}
            </bg-label-rainbow>
          </bg-grid-item>

          <bg-grid-item :col="6">
            <bg-text size="title-5">Penyewa Bisa Pilih Mandiri</bg-text>
            <bg-label-rainbow
              :color="
                formLabelFormatter.is_chooseable_by_tenant[
                  selectedFee.is_chooseable_by_tenant
                ]
              "
              class="bg-u-mt-xs"
              data-testid="is-chooseable-by-tenant-label"
            >
              {{
                formOptionsFormatter.tenant_can_choose[
                  selectedFee.is_chooseable_by_tenant
                ]
              }}
            </bg-label-rainbow>
            <bg-text class="bg-u-text-neutral-600 bg-u-mt-xxs" size="body-4">{{
              funnelFormatter(selectedFee.funnel)
            }}</bg-text>
          </bg-grid-item>
        </bg-grid>

        <bg-grid>
          <bg-grid-item :col="6">
            <bg-text size="title-5">Ketentuan Bagi Hasil</bg-text>
            <bg-label-rainbow
              v-if="isGenericFieldsShowOnly && form.mamikos_commission_type"
              class="bg-u-mt-xs"
              data-testid="commission-type-label"
            >
              {{
                commissionTypeFormatter(
                  form.mamikos_commission_type,
                  form.owner_commission_percentage
                )
              }}
            </bg-label-rainbow>
            <template v-else-if="!isGenericFieldsShowOnly">
              <bg-radio
                v-for="(item, index) in formOptions.mamikos_commission_type"
                :key="`shared-commission-type-${index}`"
                v-model="form.mamikos_commission_type"
                :id="`shared-commission-type-${index}`"
                :label="formOptionsFormatter.mamikos_commission_type[item]"
                :custom-value="item"
                class="bg-u-mt-xs"
                :data-testid="`shared-commission-type-radio-${index}`"
              />
            </template>

            <bg-grid
              v-if="!isGenericFieldsShowOnly"
              v-show="form.mamikos_commission_type"
              :v-align="'center'"
              class="bg-u-mt-md"
            >
              <bg-grid-item
                :col="4"
                class="additional-fee-modal__commission-field"
              >
                <bg-input
                  v-show="form.mamikos_commission_type !== 'manually_adjusted'"
                  :value="form.owner_commission_percentage"
                  suffix="%"
                  disabled
                  data-testid="commission-percentage-field"
                />
                <bg-input
                  v-show="form.mamikos_commission_type === 'manually_adjusted'"
                  v-model="form.owner_commission_percentage"
                  suffix="%"
                  :error="commissionPercentageValidation.hasError"
                  @input="handleInputPercentage"
                  data-testid="commission-percentage-field"
                />
              </bg-grid-item>
              <bg-grid-item
                :col="8"
                :class="[
                  'additional-fee-modal__commission-field',
                  'additional-fee-modal__commission-field--description',
                ]"
              >
                <bg-text size="body-2"> ke Pemilik </bg-text>
              </bg-grid-item>
              <bg-grid-item v-show="commissionPercentageValidation.hasError">
                <bg-text size="body-4" class="bg-u-text-red-500">{{
                  commissionPercentageValidation.message
                }}</bg-text>
              </bg-grid-item>
            </bg-grid>
          </bg-grid-item>

          <bg-grid-item :col="6">
            <bg-text size="title-5">Jenis Biaya</bg-text>
            <bg-label-rainbow
              v-if="isGenericFieldsShowOnly && form.fee_type"
              :color="form.fee_type === 'fixed' ? 'green' : 'yellow'"
              class="bg-u-mt-xs"
            >
              {{ formOptionsFormatter.fee_type[form.fee_type] }}
            </bg-label-rainbow>
            <template v-else-if="!isGenericFieldsShowOnly">
              <bg-radio
                v-for="(item, index) in formOptions.fee_type"
                :key="`fee-type-${index}`"
                v-model="form.fee_type"
                :id="`fee-type-${index}`"
                :label="formOptionsFormatter.fee_type[item]"
                :custom-value="item"
                :disabled="
                  (isPricingMethodDefinedByBilling ||
                    selectedFee.is_chooseable_by_tenant) &&
                  item === 'fixed'
                "
                class="bg-u-mt-xs"
                :data-testid="`fee-type-radio-${index}`"
              />
            </template>

            <template v-if="form.fee_type === 'fixed'">
              <bg-text size="title-5" class="bg-u-mt-md">
                Biaya Termasuk Harga Sewa
              </bg-text>
              <bg-label-rainbow
                v-if="isGenericFieldsShowOnly"
                :color="form.is_fee_included_in_listing ? 'green' : 'yellow'"
                class="bg-u-mt-xs"
              >
                {{
                  formOptionsFormatter.is_fee_included_in_listing[
                    form.is_fee_included_in_listing
                  ]
                }}
              </bg-label-rainbow>
              <template v-else-if="!isGenericFieldsShowOnly">
                <bg-radio
                  v-for="(
                    item, index
                  ) in formOptions.is_fee_included_in_listing"
                  :key="`is-fee-included-in-listing-${index}`"
                  v-model="form.is_fee_included_in_listing"
                  :id="`is-fee-included-in-listing-${index}`"
                  :label="item ? 'Ya' : 'Tidak'"
                  :custom-value="item"
                  :disabled="
                    !selectedFee.is_chooseable_by_tenant &&
                    form.fee_type === 'fixed' && !item
                  "
                  class="bg-u-mt-xs"
                />
              </template>
            </template>

            <template
              v-if="
                form.fee_type === 'fixed' && form.is_fee_included_in_listing
              "
            >
              <bg-text size="title-5" class="bg-u-mt-md">
                Pengaturan di Rincian Sewa
              </bg-text>
              <bg-label-rainbow
                v-if="isGenericFieldsShowOnly && form.fee_appearance_option"
                class="bg-u-mt-xs"
              >
                {{
                  formOptionsFormatter.fee_appearance_option[
                    form.fee_appearance_option
                  ]
                }}
              </bg-label-rainbow>
              <template v-else-if="!isGenericFieldsShowOnly">
                <bg-radio
                  v-for="(item, index) in formOptions.fee_appearance_option"
                  :key="`fee-appearance-option-${index}`"
                  v-model="form.fee_appearance_option"
                  :id="`fee-appearance-option-${index}`"
                  :label="formOptionsFormatter.fee_appearance_option[item]"
                  :custom-value="item"
                  :disabled="
                    !selectedFee.is_chooseable_by_tenant &&
                    form.fee_type === 'fixed' &&
                    item !== 'name_visible'
                  "
                  class="bg-u-mt-xs"
                />
              </template>
            </template>
          </bg-grid-item>
        </bg-grid>
      </div>

      <div
        v-if="!isEdit || isEditListingLevel"
        class="additional-fee-modal__information-wrapper bg-u-mt-lg"
        data-testid="modal-specific-information"
      >
        <bg-grid v-if="timeUnitOptionsFormatted.length">
          <bg-grid-item :col="6">
            <bg-text size="title-5" class="bg-u-mb-xs">
              Satuan Waktu Biaya</bg-text
            >
            <search-checkbox
              name="time-unit"
              placeholder="Pilih satuan waktu biaya"
              :list="timeUnitOptionsFormatted"
              :checked="form.time_unit"
              :max-checked="0"
              disable-search
              disable-clear
              selected-items-to-show="label"
              @emit-checked-array="form.time_unit = $event"
            />
          </bg-grid-item>
        </bg-grid>

        <bg-grid
          v-if="
            !isPricingMethodDefinedByBilling && selectedFee.time_unit.length
          "
        >
          <bg-grid-item v-if="form.time_unit.includes('daily')" :col="6">
            <input-currency-masking
              v-model="form.price_of_time_unit.daily"
              field-label="Harga Harian"
              placeholder="Rp"
              :prefix="
                setupCurrencyPrefix(form.price_of_time_unit.daily)
              "
              :field-error="!!priceValidation.daily"
              :field-message="priceValidation.daily"
              ref="1"
              is-allow-zero
            />
          </bg-grid-item>
          <bg-grid-item v-if="form.time_unit.includes('weekly')" :col="6">
            <input-currency-masking
              v-model="form.price_of_time_unit.weekly"
              field-label="Harga Mingguan"
              placeholder="Rp"
              :prefix="
                setupCurrencyPrefix(form.price_of_time_unit.weekly)
              "
              :field-error="!!priceValidation.weekly"
              :field-message="priceValidation.weekly"
              ref="2"
              is-allow-zero
            />
          </bg-grid-item>
          <bg-grid-item v-if="form.time_unit.includes('monthly')" :col="6">
            <input-currency-masking
              v-model="form.price_of_time_unit.monthly"
              field-label="Harga Bulanan"
              placeholder="Rp"
              :prefix="
                setupCurrencyPrefix(form.price_of_time_unit.monthly)
              "
              :field-error="!!priceValidation.monthly"
              :field-message="priceValidation.monthly"
              ref="3"
              is-allow-zero
            />
          </bg-grid-item>
        </bg-grid>

        <bg-grid v-if="feeUnitOptionsFormatted.length">
          <bg-grid-item :col="6">
            <bg-text size="title-5" class="bg-u-mb-xs"
              >Satuan Besaran Biaya</bg-text
            >
            <bg-select
              v-model="form.fee_unit"
              :options="feeUnitOptionsFormatted"
              placeholder="Pilih satuan besaran biaya"
              data-testid="search-fee-unit"
            />
          </bg-grid-item>
        </bg-grid>

        <bg-grid
          v-if="
            !isPricingMethodDefinedByBilling &&
            !selectedFee.time_unit.length &&
            !!form.fee_unit
          "
        >
          <bg-grid-item :col="6">
            <input-currency-masking
              v-model="form.fee_unit_value"
              :field-label="`Harga ${
                formOptionsFormatter.fee_unit[form.fee_unit]
              }`"
              :prefix="form.fee_unit_value ? 'Rp' : ''"
              :placeholder="form.fee_unit_value ? '' : 'Rp'"
              :field-error="!!priceValidation.fee_unit"
              :field-message="priceValidation.fee_unit"
            />
          </bg-grid-item>
        </bg-grid>
      </div>
    </template>

    <template #footer>
      <div class="flex align-center justify-end">
        <bg-button
          variant="primary"
          :loading="isLoadingSaveAdditionalFee"
          :disabled="!isSubmitBtnEnabled"
          @click="handleSave"
        >
          {{ isEdit ? 'Ubah' : 'Tambah' }}
        </bg-button>
      </div>
    </template>
  </bg-modal>
</template>

<script>
/* eslint-disable camelcase */
import {
  BgInput,
  BgModal,
  BgButton,
  BgField,
  BgText,
  BgGrid,
  BgGridItem,
  BgSelect,
  BgSwitch,
  BgLabelRainbow,
  BgRadio,
  BgSkeleton,
} from 'bangul-vue';
import InputCurrencyMasking from '@admin_molecules/InputCurrencyMasking';
import SearchCheckbox from '@admin_molecules/SearchCheckbox';

import debounce from 'Utils/debounce';
import { capitalize } from 'Utils/typography';
import { endpoints } from '@admin_endpoints/additional-fee';
import {
  additionalFeeFormOptions,
  additionalFeeFormatter,
  additionalFeeLabelFormatter,
} from './_const/const';
import { cleanNumber, onlyNumber } from '@/utils/formatter';

export default {
  name: 'AdditionalFeeModal',

  components: {
    BgInput,
    BgModal,
    BgButton,
    BgField,
    BgText,
    BgGrid,
    BgGridItem,
    BgSelect,
    BgSwitch,
    BgLabelRainbow,
    BgRadio,
    BgSkeleton,
    InputCurrencyMasking,
    SearchCheckbox,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: /* istanbul ignore next */ () => {},
    },
    level: {
      type: String,
      default: 'general',
      validator: type => ['general', 'listing'].indexOf(type) >= 0,
    },
    editData: {
      type: Object,
      default: /* istanbul ignore next */ () => {},
    },
  },

  data() {
    return {
      isLoadingSaveAdditionalFee: false,
      isLoadingCheckAdditionalFee: false,
      form: {
        isToAllListing: true,
        additional_fee_rule_id: null,
        room_ids: [],
        mamikos_commission_type: null,
        owner_commission_percentage: null,
        fee_type: null,
        is_fee_included_in_listing: null,
        fee_appearance_option: null,
        time_unit: [],
        price_of_time_unit: {
          daily: null,
          weekly: null,
          monthly: null,
        },
        fee_unit: null,
        fee_unit_value: null,
      },
      selectedFee: null,
      formOptions: additionalFeeFormOptions,
      formOptionsFormatter: additionalFeeFormatter,
      formLabelFormatter: additionalFeeLabelFormatter,
      roomsWithoutAdditionalFee: [],
      priceValidation: {},
      commissionPercentageValidation: {
        hasError: false,
        message: '',
      },
    };
  },

  computed: {
    isEdit() {
      return !!this.editData && Object.keys(this.editData).length > 0;
    },
    isEditListingLevel() {
      return this.isEdit && this.level === 'listing';
    },
    propertyId() {
      return this.$route.params.propertyId;
    },
    additionalFeeOptionsFormatted() {
      const options = this.formData?.additional_fees?.filter(
        option => !option.is_applied_to_all_room
      );
      return options;
    },
    listingOptionsFormatted() {
      const options = this.roomsWithoutAdditionalFee.map(option => ({
        value: option.id,
        label: option.name,
      }));
      return options;
    },
    timeUnitOptionsFormatted() {
      const options = this.selectedFee?.time_unit.map(option => ({
        value: option,
        label: this.formOptionsFormatter.time_unit[option],
      }));
      return options;
    },
    feeUnitOptionsFormatted() {
      const options = this.selectedFee?.fee_unit.map(option => ({
        val: option,
        label: this.formOptionsFormatter.fee_unit[option],
      }));
      return options;
    },
    isSubmitBtnEnabled() {
      const { selectedFee, form } = this;
      const {
        isToAllListing,
        room_ids,
        is_fee_included_in_listing: isFeeIncludedInListing,
        fee_appearance_option: feeAppearanceOption,
        fee_unit: feeUnit,
        fee_unit_value: feeUnitValue,
        time_unit: timeUnit,
        ...rest
      } = form;
      const mandatoryField = rest;

      const isRoomIdRequired = !isToAllListing ? room_ids.length : true;

      // Validation for Upper Section
      const isAllFieldFilled = !Object.values(mandatoryField).some(
        value => value === null || value === ''
      );
      const isFeeTypeFixedAndFeeIncludedNull =
        mandatoryField.fee_type === 'fixed' && isFeeIncludedInListing === null;
      const isFeeIncludedAndFeeAppearanceNull =
        isFeeIncludedInListing && feeAppearanceOption === null;
      const isGeneralInformationValid =
        isAllFieldFilled &&
        !isFeeTypeFixedAndFeeIncludedNull &&
        !isFeeIncludedAndFeeAppearanceNull &&
        !this.commissionPercentageValidation.hasError;
      //

      // Validation for Under Section
      let isSpecificInformationWithoutPricingValid = false;
      const lengthOfTimeUnit = timeUnit.length;
      const lengthOfFeeUnit = !!feeUnit;
      const valueOfFeeUnit = feeUnitValue;

      const hasTimeUnitOptions = selectedFee?.time_unit.length;
      const hasFeeUnitOptions = selectedFee?.fee_unit.length;

      if (this.isPricingMethodDefinedByBilling) {
        if (hasTimeUnitOptions && hasFeeUnitOptions) {
          isSpecificInformationWithoutPricingValid =
            lengthOfTimeUnit && lengthOfFeeUnit;
        } else if (hasTimeUnitOptions) {
          isSpecificInformationWithoutPricingValid = lengthOfTimeUnit;
        } else if (hasFeeUnitOptions) {
          isSpecificInformationWithoutPricingValid = lengthOfFeeUnit;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (hasTimeUnitOptions && hasFeeUnitOptions) {
          isSpecificInformationWithoutPricingValid =
            lengthOfTimeUnit && lengthOfFeeUnit;
        } else if (hasTimeUnitOptions) {
          isSpecificInformationWithoutPricingValid = lengthOfTimeUnit;
        } else if (hasFeeUnitOptions && valueOfFeeUnit) {
          isSpecificInformationWithoutPricingValid =
            lengthOfFeeUnit && valueOfFeeUnit;
        }
      }
      //

      const isPriceValid = !this.isPricingMethodDefinedByBilling
        ? Object.keys(this.priceValidation).length !== 0 &&
          Object.values(this.priceValidation).every(value => value === null)
        : true;

      return (
        isRoomIdRequired &&
        isGeneralInformationValid &&
        ((this.isEdit && !this.isEditListingLevel) ||
          isSpecificInformationWithoutPricingValid) &&
        ((this.isEdit && !this.isEditListingLevel) ||
          !selectedFee?.time_unit.length ||
          isPriceValid)
      );
    },
    isGenericFieldsShowOnly() {
      return (
        this.isEditListingLevel ||
        (this.isEdit
          ? false
          : this.roomsWithoutAdditionalFee.length !==
            this.formData?.rooms?.length)
      );
    },
    ownerPercentage() {
      return Number(this.formData.owner_commission_percentage.replace('%', ''));
    },
    isPricingMethodDefinedByBilling() {
      return this.selectedFee?.pricing_method === 'defined_by_billing';
    },
  },

  watch: {
    isOpen: {
      immediate: true,
      handler(value) {
        if (value && this.isEdit) {
          this.populateEditData();
        }
      },
    },
    'form.additional_fee_rule_id': {
      deep: true,
      async handler(value) {
        if (!value || this.isEdit) {
          return;
        }

        this.resetForm();
        this.selectedFee = this.additionalFeeOptionsFormatted?.filter(
          fee => fee.id === value
        )[0];

        await this.checkForAdditionalFee();
      },
    },
    'form.mamikos_commission_type': {
      deep: true,
      handler() {
        const commissionType = this.form.mamikos_commission_type;
        if (commissionType === 'basic_commission') {
          this.form.owner_commission_percentage = this.ownerPercentage;
        } else if (commissionType === 'full_to_owner') {
          this.form.owner_commission_percentage = 100;
        }

        this.validateCommissionPercentage();
      },
    },
    'form.owner_commission_percentage': {
      deep: true,
      handler() {
        this.validateCommissionPercentage();
      },
    },
    'form.price_of_time_unit': {
      deep: true,
      handler() {
        this.validatePriceOfTimeUnit();
      },
    },
    'form.time_unit': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.validatePriceOfTimeUnit();
        }
      },
    },
    'form.fee_type': {
      deep: true,
      handler(value) {
        if (value === 'optional') {
          this.form.is_fee_included_in_listing = false;
          this.form.fee_appearance_option = null;
        } else if (value === 'fixed') {
          this.form.is_fee_included_in_listing = true;
          this.form.fee_appearance_option = 'name_visible';
        }
      },
    },
    'selectedFee.is_chooseable_by_tenant': {
      deep: true,
      handler(value) {
        if (value) {
          this.form.fee_type = 'optional';
        }
      },
    },
    isPricingMethodDefinedByBilling(value) {
      if (value) {
        this.form.fee_type = 'optional';
      }
    },
  },

  methods: {
    closeModal() {
      this.form.additional_fee_rule_id = null;
      this.resetForm();
      this.$emit('update:isOpen', false);
    },

    populateEditData() {
      const {
        id,
        owner_commission_type,
        owner_commission_percentage,
        fee_type,
        is_fee_included_in_listing,
        is_fee_name_appear,
        is_fee_amount_appear,
        selectedRoom: { amounts } = {},
      } = this.editData;

      this.form.additional_fee_rule_id = id;
      this.form.mamikos_commission_type = owner_commission_type;
      this.form.owner_commission_percentage = owner_commission_type === 'basic_commission' 
        ? this.ownerPercentage
        : owner_commission_percentage.replace(
          '%',
          ''
        );
      this.form.fee_type = fee_type;
      this.form.is_fee_included_in_listing = !!is_fee_included_in_listing;
      this.form.fee_appearance_option = this.feeVisibilityFormatter(
        is_fee_name_appear,
        is_fee_amount_appear
      );
      amounts?.forEach((item, index) => {
        if (item.time_unit) {
          this.form.time_unit.push(item.time_unit);
          this.form.price_of_time_unit[item.time_unit] = this.cleanPrice(
            item.amount
          );
        }
        if (item.fee_unit && index === 0) {
          this.form.fee_unit = item.fee_unit;

          if (!item.time_unit) {
            this.form.fee_unit_value = this.cleanPrice(item.amount);
          }
        }
      });

      this.selectedFee = this.formData?.additional_fees?.filter(
        fee => fee.id === id
      )[0];
      
      if (this.selectedFee.is_chooseable_by_tenant) {
        this.form.fee_type = 'optional';
      }

      if (this.isPricingMethodDefinedByBilling) {
        this.form.fee_type = 'optional';
      }
    },

    feeVisibilityFormatter(feeVisibility, amountVisibility) {
      if (feeVisibility && amountVisibility) {
        return 'name_price_visible';
      } else if (!feeVisibility && !amountVisibility) {
        return 'name_price_invisible';
      }

      return 'name_visible';
    },

    async checkForAdditionalFee() {
      this.isLoadingCheckAdditionalFee = true;

      const {
        // eslint-disable-next-line no-unused-vars
        debug,
        rooms: roomsWithAdditionalFee,
        ...rest
      } = await endpoints.checkPropertyForAdditionalFeeExistence(
        this.propertyId,
        this.form.additional_fee_rule_id
      );
      const otherData = rest;

      if (Object.keys(otherData).length > 0) {
        this.form.fee_type = otherData.fee_type;
        this.form.mamikos_commission_type = otherData.mamikos_commission_type;
        this.form.owner_commission_percentage =
          parseInt(Number(otherData.owner_commission_percentage) * 100);
        this.form.is_fee_included_in_listing = !!otherData.is_fee_included_in_listing;
        this.form.fee_appearance_option = otherData.fee_appearance_option;
      }

      this.roomsWithoutAdditionalFee = this.formData?.rooms?.filter(
        room =>
          !(roomsWithAdditionalFee || []).some(
            roomWithAdditionalFee => room.id === roomWithAdditionalFee.id
          )
      );

      this.isLoadingCheckAdditionalFee = false;
    },

    resetForm() {
      this.form.isToAllListing = true;
      this.form.room_ids = [];
      this.form.mamikos_commission_type = null;
      this.form.owner_commission_percentage = null;
      this.form.fee_type = null;
      this.form.is_fee_included_in_listing = null;
      this.form.fee_appearance_option = null;
      this.form.time_unit = [];
      this.form.price_of_time_unit = {
        daily: null,
        weekly: null,
        monthly: null,
      };
      this.form.fee_unit = null;
      this.form.fee_unit_value = null;
    },

    getPayload() {
      let payload;

      const {
        room_ids,
        isToAllListing,
        time_unit,
        price_of_time_unit,
        fee_unit_value,
        owner_commission_percentage,
        is_fee_included_in_listing,
        ...rest
      } = this.form;
      payload = rest;

      const roomIds = isToAllListing
        ? this.listingOptionsFormatted.map(room => room.value)
        : room_ids;
      const commissionPercentage = owner_commission_percentage / 100;
      const timeBasedPrice = time_unit.map(time => ({
        unit: time,
        amount: this.cleanPrice(price_of_time_unit[time]),
      }));
      const feeUnitPrice = this.cleanPrice(fee_unit_value);
      const isFeeIncludedInListing = !!is_fee_included_in_listing;

      payload = {
        ...payload,
        room_ids: roomIds,
        owner_commission_percentage: commissionPercentage,
        time_based_price: timeBasedPrice,
        fee_unit_value: feeUnitPrice,
        is_fee_included_in_listing: isFeeIncludedInListing,
      };

      return payload;
    },

    async handleSave() {
      const isValid = this.isSubmitBtnEnabled;

      if (isValid) {
        const payload = this.getPayload();

        if (!this.isEdit) {
          this.addAdditionalFee(payload);
        } else if (this.isEditListingLevel) {
          this.editAdditionalFeeListingLevel(payload);
        } else {
          this.editAdditionalFeeGeneralLevel(payload);
        }
      }
    },

    async addAdditionalFee(payload) {
      this.isLoadingSaveAdditionalFee = true;

      const response = await endpoints.createPropertyAdditionalFee(
        this.propertyId,
        payload
      );

      if (response.status === 400 || response.status === 422) {
        this.$toast.show(response.data?.error);
      } else if (!(response.data && response.data?.error_code)) {
        this.$toast.show('Biaya berhasil ditambah.');
        this.$emit('action-success-submit');
      }

      this.closeModal();
      this.isLoadingSaveAdditionalFee = false;
    },

    async editAdditionalFeeGeneralLevel(payload) {
      this.isLoadingSaveAdditionalFee = true;

      const {
        fee_type,
        mamikos_commission_type,
        owner_commission_percentage,
        is_fee_included_in_listing,
        fee_appearance_option,
      } = payload;

      const data = {
        fee_type,
        mamikos_commission_type,
        owner_commission_percentage,
        is_fee_included_in_listing,
        fee_appearance_option,
      };

      const response = await endpoints.editPropertyAdditionalFeeGeneralLevel({
        propertyId: this.propertyId,
        additionalFeeRuleId: this.form.additional_fee_rule_id,
        data,
      });

      if (!(response.data && response.data?.error_code)) {
        this.$toast.show('Biaya berhasil diubah.');
        this.$emit('action-success-submit');
      } else {
        this.$toast.show(response.data?.error, { variant: 'error' });
      }

      this.closeModal();
      this.isLoadingSaveAdditionalFee = false;
    },

    async editAdditionalFeeListingLevel(payload) {
      this.isLoadingSaveAdditionalFee = true;

      const { fee_unit, fee_unit_value, time_based_price } = payload;
      const data = {
        fee_unit,
        fee_unit_value,
        time_based_price,
      };

      const response = await endpoints.editPropertyAdditionalFeeListingLevel({
        propertyId: this.propertyId,
        additionalFeeRuleId: this.form.additional_fee_rule_id,
        roomId: this.editData.selectedRoom.id,
        data,
      });

      if (!(response.data && response.data?.error_code)) {
        this.$toast.show('Biaya berhasil diubah.');
        this.$emit('action-success-submit');
      } else {
        this.$toast.show(response.data?.error, { variant: 'error' });
      }

      this.closeModal();
      this.isLoadingSaveAdditionalFee = false;
    },

    handleInputPercentage(value) {
      const checkedValue = onlyNumber(value);
      this.form.owner_commission_percentage =
        Number(checkedValue) > 100 ? checkedValue.slice(0, -1) : checkedValue;
    },

    cleanPrice(value) {
      return value === 0 ? value : value && cleanNumber(value);
    },

    setupCurrencyPrefix(value) {
      return value === 0 ? 'Rp' : value && 'Rp';
    },

    // eslint-disable-next-line func-names
    validatePriceOfTimeUnit: debounce(function () {
      const selectedTimeUnit = this.form.time_unit;
      const { daily, weekly, monthly } = this.form.price_of_time_unit;
      const dailyPrice = this.cleanPrice(daily);
      const weeklyPrice = this.cleanPrice(weekly);
      const monthlyPrice = this.cleanPrice(monthly);

      const validations = {
        daily: {
          validate: () => {
            if (dailyPrice === '' || dailyPrice === null) {
              return 'Harus diisi';
            }

            if (
              selectedTimeUnit.includes('weekly') &&
              (weeklyPrice <= dailyPrice ||
                (selectedTimeUnit.includes('monthly') &&
                  monthlyPrice <= dailyPrice))
            ) {
              return 'Harga harus lebih rendah dari harga mingguan/bulanan';
            }
            return null;
          },
        },
        weekly: {
          validate: () => {
            if (weeklyPrice === '' || weeklyPrice === null) {
              return 'Harus diisi';
            }

            if (
              selectedTimeUnit.includes('daily') &&
              dailyPrice >= weeklyPrice
            ) {
              return 'Harga harus lebih tinggi dari harga harian';
            }
            if (
              selectedTimeUnit.includes('monthly') &&
              monthlyPrice <= weeklyPrice
            ) {
              return 'Harga harus lebih rendah dari harga bulanan';
            }
            return null;
          },
        },
        monthly: {
          validate: () => {
            if (monthlyPrice === '' || monthlyPrice === null) {
              return 'Harus diisi';
            }

            if (
              selectedTimeUnit.includes('daily') &&
              (dailyPrice >= monthlyPrice ||
                (selectedTimeUnit.includes('weekly') &&
                  weeklyPrice >= monthlyPrice))
            ) {
              return 'Harga harus lebih tinggi dari harga harian/mingguan';
            }
            return null;
          },
        },
      };

      const validation = {};
      this.form.time_unit.forEach(unit => {
        if (validations[unit]) {
          const validationMessage = validations[unit].validate();
          validation[unit] = validationMessage;
        }
      });
      this.priceValidation = validation;
    }, 500),

    commissionTypeFormatter(commissionType, percentage) {
      if (commissionType === 'basic_commission') {
        return 'Sesuai Basic Commission';
      } else if (commissionType === 'full_to_owner') {
        return 'Full ke Pemilik';
      }

      return `${percentage}% ke Pemilik`;
    },

    funnelFormatter(value) {
      return value.map(capitalize).join(' dan ');
    },

    validateCommissionPercentage() {
      const commissionPercentage = this.form.owner_commission_percentage;
      const commissionType = this.form.mamikos_commission_type;

      if (
        Number(commissionPercentage) === 100 &&
        commissionType === 'manually_adjusted'
      ) {
        this.commissionPercentageValidation.hasError = true;
        this.commissionPercentageValidation.message =
          'Pilih Full ke Pemilik jika bagi hasil 100% ke pemilik.';
      } else {
        this.commissionPercentageValidation.hasError = false;
        this.commissionPercentageValidation.message = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./AdditionalFeeModal.scss"></style>
